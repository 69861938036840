import { fetchGet, fetchPost } from '../index';

//! 获取概览数据
const getOverviewValue = (params: any): Promise<any> => {
    return fetchPost('/claimapi/delay/apply/count', params);
};

//! search 查询接口
const searchData = (params: any): Promise<any> => {
    return fetchPost('/claimapi/delay/apply/search', params);
};

//! 下载文件附件
const getDocument = (params: any): Promise<any> => {
    return fetchGet('/claimapi/delay/apply/downloadFile', params);
};
export default {
    getOverviewValue,
    searchData,
    getDocument,
};
